import apiAxios from "@/api-axios";

export default {
  state: {
    customerBureauStatus: {},
    businessBureauStatus: {},
    operatorDashboardData: {},
  },
  mutations: {
    setCustomerBureauStatus(state, customers) {
      state.customerBureauStatus = customers;
    },
    clearCustomerBureauStatus(state) {
      state.customerBureauStatus = {};
    },
    setBusinessBureauStatus(state, customers) {
      state.businessBureauStatus = customers;
    },
    clearBusinessBureauStatus(state) {
      state.businessBureauStatus = {};
    },
    setOperatorDashboardData(state, data) {
      state.operatorDashboardData = data;
    },
    clearOperatorDashboardData(state) {
      state.operatorDashboardData = {};
    },
  },
  getters: {
    customerBureauStatus: s => s.customerBureauStatus,
    businessBureauStatus: s => s.businessBureauStatus,
    operatorDashboardData: s => s.operatorDashboardData,
  },
  actions: {
    async apiGetDashboardStatus({commit, dispatch}, params) {
      try {
        const response = await apiAxios.get('/dashboard/status/', params, apiAxios.getParamsSerializerConfig());
        const customerBureauStatus = {};
        for (let customer of response.data) {
          customerBureauStatus[customer.userId] = customer;
        }
        await commit('setCustomerBureauStatus', customerBureauStatus);
        return response.data;
      } catch (e) {
        await commit('clearCustomerBureauStatus');
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetDashboardBusinessStatus({commit, dispatch}, params) {
      try {
        const response = await apiAxios.get('/dashboard/business/status/', params, apiAxios.getParamsSerializerConfig());
        const companyBureauStatus = {};
        for (let company of response.data) {
          companyBureauStatus[company.businessId] = company;
        }
        await commit('setBusinessBureauStatus', companyBureauStatus);
        return response.data;
      } catch (e) {
        await commit('clearBusinessBureauStatus');
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetDashboardUpdate({dispatch}, params) {
      try {
        if (!params.lastUpdatedAt.length) {
          delete params.lastUpdatedAt;
        }
        const response = await apiAxios.get('/dashboard/update/', params, apiAxios.getParamsSerializerConfig());
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiSetDashboardData({dispatch}, {value}) {
      try {
        const response = await apiAxios.post('/dashboard/settings/', {value});
        return response.data;
      }catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiGetDashboardData({dispatch}) {
      try {
        const response = await apiAxios.get('/dashboard/settings/');
        return response.data||{};
      }catch (e) {
        dispatch('catchError', e);
      }
      return {};
    }

  }
}
