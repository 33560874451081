import apiAxios from "@/api-axios";
import router from "@/router";

export default {
  state: {
    user: {},
    profile: {},
    userProfileCache: {},
    appAPIVersion: '0.0.0',
    appAMAVersion: '0.0.0',
  },
  mutations: {
    setAppAPIVersion(state, ver) {
      state.appAPIVersion = ver;
    },
    setAppAMAVersion(state, ver) {
      state.appAMAVersion = ver;
    },
    setUser(state, user) {
      state.user = user;
    },
    setProfile(state, profile) {
      if (profile.postal_address === profile.address) {
        profile.same_address = true;
      }
      if (!profile.address_residence) {
        profile.address_residence = {
          state: "",
          city: "",
          street: "",
          apartment: "",
          zip_first: null,
          zip_second: null
        };
      }
      if (!profile.address_postal) {
        profile.address_postal = {
          state: "",
          city: "",
          street: "",
          apartment: "",
          zip_first: null,
          zip_second: null
        };
      }
      if (profile.address_postal === profile.address_residence) {
        profile.same_address = true;
      }
      state.profile = profile;
    },
    setUserProfileCache(state, [uId, profile]) {
      state.userProfileCache[uId] = profile;
    },
    clearUserProfileCache(state) {
      state.userProfileCache = {};
    },
    clearUser(state) {
      state.user = {};
      state.profile = {};
    }
  },
  getters: {
    user: s => s.user,
    profile: s => s.profile,
    userProfileCache: s=>s.userProfileCache,
    appAPIVersion: s=>s.appAPIVersion,
    appAMAVersion: s=>s.appAMAVersion,
  },
  actions: {
    async apiLoginUser({commit, dispatch}, user) {
      try {
        const response = await apiAxios.post('/users/login', user);
        commit('setUser', response.data);
        return response.data;
      } catch (e) {
        dispatch('apiLogoutUser');
        dispatch('catchError', e);
      }
    },
    async apiLoginCodeEmail({dispatch}, [email, password]) {
      try {
        const response = await apiAxios.post('/users/login_code', {email, password});
        return response.data;
      }catch (e) {
        dispatch('catchError', e);
      }
    },

    async apiLoginUserConfirm({commit, dispatch}, user) {
      try {
        const response = await apiAxios.post('/users/login_confirm', user);

        await commit('setAccessToken', `${response.data.token_type} ${response.data.access_token}`);
        dispatch('initTimeoutRefreshToken');
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },

    async apiLogoutUser({commit}) {
      await commit('clearUser');
      await commit('clearAccessToken');
      await apiAxios.removeToken();
      router.push({name: 'sing-in'});
    },


    // Me By Access Token

    async apiGetUserMe({commit, dispatch}) {
      try {
        const response = await apiAxios.get('/users/me/');
        await commit('setUser', response.data);
        return response.data;
      } catch (e) {
        dispatch('apiLogoutUser');
        dispatch('catchError', e);
      }
      return {};
    },
    async apiGetProfileMe({commit, dispatch}) {
      try {
        const response = await apiAxios.get('/users/me/profile/');
        await commit('setProfile', response.data);
        await commit('setAppAPIVersion', response.headers?.['x-api-version']);
        await commit('setAppAMAVersion', response.headers?.['x-api-version']);
        return response.data;
      } catch (e) {
        dispatch('apiLogoutUser');
        dispatch('catchError', e);
      }
    },

    async apiGetProfile({dispatch}, uId) {
      try {
        const response = await apiAxios.get(`/users/${uId}/profile`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiUpdateProfile({dispatch}, {userId, data}) {
      try {
        const response = await apiAxios.put(`/users/${userId}/profile/`, data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetUser({dispatch}, uId) {
      try {
        const response = await apiAxios.get('/users/' + uId);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },

    async apiGetUsers({dispatch}, params) {
      try {
        const response = await apiAxios.get('/users/', params);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiPatchStatus({dispatch}, {userId, data}) {
      try {
        const response = await apiAxios.patch(`/users/${userId}/`, data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiGetLocalFindUser({dispatch}, user_name) {
      let params = {
        is_local: true,
        archived: false,
      };
      if (user_name.length){
        params.user_name = user_name;
      }
      return (await dispatch('apiGetUserLocal', params))?.users||[];
    },
    async apiGetArchivedFindUser({dispatch}, user_name) {
      let params = {
        archived: true,
      };
      if (user_name.length){
        params.user_name = user_name;
      }
      return (await dispatch('apiGetUserLocal', params))?.users||[];
    },
    async apiGetUserLocal({dispatch}, params) {
      try {
        const response = await apiAxios.get('/users/local/', params);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiUserUpdated({dispatch}, lastUpdatedAt) {
      try {
        const response = await apiAxios.get('/users/update/', lastUpdatedAt?{lastUpdatedAt}:undefined);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },

    async apiUserUploadSSN({dispatch}, {userId, formData}) {
      try {
        const response = await apiAxios.axios.post(`/users/upload_ssn?user_id=${userId}`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiUserUploadIdPhoto({dispatch}, {userId, type_photo, formData}) {
      try {
        const response = await apiAxios.axios.post(`/users/idphoto/?user_id=${userId}&type_photo=${type_photo}`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiUserDeleteOtherFile({dispatch}, id) {
      try {
        const response = await apiAxios.axios.delete(`/users/other_file/?file_id=${id}`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiUserUploadOtherFiles({dispatch}, {userId, formData, onUploadProgress}) {
      try {
        const response = await apiAxios.axios.post(
          `/users/upload_other?user_id=${userId}`,
          formData,
          {
            headers: {'Content-Type': 'multipart/form-data'},
            onUploadProgress,
          });
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiCreateLocalClient({dispatch}, data) {
      try {
        const response = await apiAxios.post('/users/add/', data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },

  }
}
